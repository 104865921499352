class AC {
  constructor() {
    const data = this.getData();
    this.getData = async () => $.extend(true, {}, await data);
  }
  async loadData() {
    const res = await fetch('ajax/films.php');
    return await res.json();
  }
  async getData() {
    console.log('data');
    const [data, tc] = await Promise.all([
      this.loadData(),
      new TicketingCine(),
    ]);
    const now = (data.time = new Date(data.time));
    const filmsTC = {};
    for (const film of data.films) {
      const filmTC = tc.getFilm(film);
      filmsTC[film.id] = filmTC;
      film.booking_url = filmTC && filmTC.booking_url;
    }
    data.horaires = data.horaires
      .sort((a, b) => a.date - b.date)
      .filter(function (o) {
        if (
          data.films.find(function (o2) {
            return o2.id == o.film;
          }).tags.hidden
        )
          return false;
        o.date = new Date(o.date);
        o.diff = dayDiff(o.date, now);
        return o.diff.diff >= 0;
      });
    const horaires = {};
    for (const h of data.horaires) {
      (horaires[h.film] = horaires[h.film] || []).push(h);
    }
    var films = [];
    for (const [key, horaire] of Object.entries(horaires)) {
      data.films.find(function (o) {
        return o.id == key;
      }).horaires = horaire.sort((a, b) => a.date - b.date);
    }
    data.films = data.films.filter(o => !o.tags.hidden && 'horaires' in o);
    for (let key = 0; key < data.films.length; key++) {
      try {
        data.films[key].datesortie = new Date(
          data.films[key].release.releaseDate,
        );
      } catch (e) {
        data.films[key].datesortie = null;
      }
      films.push(data.films[key]);
    }
    data.films = films.sort((a, b) => a.order - b.order);

    for (const film of data.films) {
      if (film.edited) {
        $.extend(film, film.edited);
      }
    }
    for (const h of data.horaires) {
      const session = filmsTC[h.film]?.sessions?.find(
        s => s.date - h.date === 0,
      );
      if (session) h.booking_url = session.booking_url;
    }

    return data;
  }

  async getWeek() {
    const data = await this.getData();
    const horaires = {};
    for (const h of data.horaires) {
      const d = +h.date;
      (horaires[d] = horaires[d] || []).push(h);
    }
    console.log("hhh", horaires);
    data.horaires = [...Object.values(horaires)];
    const horaires2 = {};
    for (const h of data.horaires) {
      const d = '_' + h[0].date.toString('dd/MM/yyyy');
      (horaires2[d] = horaires2[d] || []).push(h);
    }
    data.horaires = horaires2;
    return data;
  }

  async getFilm(id) {
    const data = await this.getData();
    var film = data.films.find(f => f.id == id);
    const horaires = {};
    for (const h of film.horaires) {
      (horaires[h.type] = horaires[h.type] || []).push(h);
    }
    film.horaires = horaires;
    for (const k in film.horaires) {
      const horaire = {};
      for (const h of film.horaires[k]) {
        const d = h.diff.diff;
        (horaire[d] = horaire[d] || []).push(h);
      }
      film.horaires[k] = horaire;
    }
    var ret = {
      time: data.time,
      types: data.types,
      film: film,
    };
    return ret;
  }
  async getProg() {
    const data = await this.getData();
    for (const key in data.films) {
      var film = data.films[key];
      const horaires = {};
      for (const h of film.horaires) {
        const d = h.diff.diff;
        (horaires[d] = horaires[d] || []).push(h);
      }
      film.horaires = horaires;
    }
    return data;
  }
}

ac = new AC();
