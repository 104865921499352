class TicketingCine {
  constructor() {
    return (async () => {
      this.data = await this.loadData();
      return this;
    })();
  }
  async loadData() {
    const res = await fetch('./ajax/ticketingcine.php');
    const data = await res.json();
    if (!data) return { events: [] };
    for (const site of data.sites)
      for (const event of site.events)
        for (const session of event.sessions)
          session.date = new Date(session.date);

    return data.sites[0];
  }
  getFilm(acFilm) {
    const title = sanitize(acFilm.title);
    const release = acFilm?.releases?.[0]?.releaseDate?.date?.replace(/-/g, '');
    return (
      this.data.events.find(a => sanitize(a.title) === title) ||
      this.data.events.find(a => a.release_date === release)
    );
  }
}

function sanitize(str = '') {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]+/gi, ' ')
    .trim();
}
